<template>
  <main>
    <div class="container">
      <h1>Выберите себе подарок</h1>
      <hr>
      <br>
      <!-- <h1>Уважаемые коллеги!</h1> -->
      <!-- <p class="lead colored"><p>Действие мотивационной программы Pango Cars приостановлено. Обмен бонусов на&nbsp;Электронные Подарочные Сертификаты не&nbsp;доступен.</p></p> -->
      <ThisComponent></ThisComponent>
    </div>
  </main>
</template>

<script>
import Vue from 'vue'
import ThisComponent from 'base/components/Catalog'
import Swal from 'sweetalert2'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--catalog')

  this.afterMounted()
}

ThisComponent.methods.beforeAddToCart = function (productId, price) {
  Vue.$logger.debug('Catalog | Before add to cart from Component')
  return new Promise((resolve, reject) => {
    if (price.points > this.$store.state.user.balance) {
      Swal.fire({
        title: 'Недостаточно баллов',
        html: 'Вашего баланса недостаточно для&nbsp;добавления в&nbsp;корзину',
        type: 'error',
        confirmButtonText: 'Закрыть',
        confirmButtonClass: 'button button--rounded button--large button--margined',
        buttonsStyling: false
      })
      reject(new Error('beforeAddToCart | ошибка'))
    } else {
      this.$store.dispatch('cart/clear')
      resolve()
    }
  })
}

ThisComponent.methods.afterAddToCart = function () {
  Vue.$logger.debug('Catalog | After add to cart from Component')
  return new Promise((resolve, reject) => {
    this.$router.push('/order').catch(()=>{})
    resolve()
  })
}

export default {
  components: {
    ThisComponent
  }
}
</script>
